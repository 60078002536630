import { render, staticRenderFns } from "./SemenCollectionLast.vue?vue&type=template&id=dfe1944e&scoped=true&"
import script from "./SemenCollectionLast.vue?vue&type=script&lang=js&"
export * from "./SemenCollectionLast.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfe1944e",
  null
  
)

export default component.exports