<template>
<div class="color-txt">
    <semen-collectionn></semen-collectionn>
</div>
</template>

<script>
import SemenCollectionn from '../../components/semencollection/SemenCollectionn';
export default {
    name: 'SemenCollectionsTwoPage',
    components: { SemenCollectionn }
};
</script>

<style scoped>

</style>
