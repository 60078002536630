<template>
  <div>

    <div class="">
      <div class="row">
        <div class="mt-2 col-12 p-0">
          <h1 class="text-primary font-poppins-semibold fs-lg-4 mb-4">Semen Collection</h1>
        </div>
      </div>
      <semen-collectionone @loadData="loadBulls"></semen-collectionone>
    </div>
    <s-form @submit="addItem">
      <div class="row">
        <div class="card bg-grey mt-3 bs-1 pb-0 pr-0 pl-0 pt-0 pb-0 w-20r two">
          <div class="bg-white">
            <p class=" fl-x-cc text-primary font-poppins-semibold pt-2">Bull List</p>
            <p class="fl-x-cc text-primary pb-1">(Not found in excel file)</p>
          </div>
          <p style="cursor: pointer" class="pl-4 pt-2" v-for="(item, i) in bullsData" :key="i"
             @click="loadDetails(item)">{{ item.reg_no }}</p>

        </div>

        <div class="col-lg-5 ml-5 mt-5 fl-x">
          <div class="col-lg-5">
            <p>Bull Name</p>
            <p class="pt-4">Bull No</p>
            <p class="pt-4">Ejaculation No</p>
          </div>
          <div class="col-lg-5">
            <validated-input disabled class="" v-model="details.bull_name"></validated-input>
            <validated-input disabled class="" v-model="details.reg_no"></validated-input>
            <validated-input disabled class="" v-model="details.ejaculation_no"></validated-input>

          </div>
        </div>

      </div>
      <div>
        <semen-collection-last @ss="ss" @st="st" @success="loadBullsAgain" ref="sc" :details="bullsDetails" :saved-details="savedData"></semen-collection-last>
      </div>

      <div class="fl-x-br ">
        <btn type="submit" class="mr-3 px-4" :loading="loading" loading-text="Saving..." text="Save"></btn>
        <btn design="basic-b" type="reset" @click="BackToHome">Cancel</btn>
      </div>
    </s-form>
  </div>
</template>

<script>
import SemenCollectionLast from '../../components/semencollection/SemenCollectionLast';
import SemenCollectionone from '../../components/SemenCollectionone';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'SemenCollectionn',
    components: { SemenCollectionone, SemenCollectionLast },
    data () {
        return {
            loading: false,
            URL: urls.semenCollection.addEdit,
            bullsData: [],
            bullsDetails: '',
            details: {
                bull_name: '',
                reg_no: '',
                ejaculation_no: ''
            },
            savedData: ''
        };
    },
    methods: {
        async loadDetails (data) {
            console.log('data', data);
            const response = await axios.form(urls.semenCollection.bullDetails, {
                id: data.id,
                excel_rejected_flag: data.excel_rejected_flag
            });
            const result = response.data.data;
            this.bullsDetails = result;
            this.details.bull_name = result.bull_name;
            this.details.reg_no = result.reg_no;
            this.details.ejaculation_no = result.ejaculation_no;
            this.$refs.sc.loadDetails();
        },

        BackToHome () {
            this.$router.push({ path: '/semen-station/' });
        },
        loadBullsAgain () {
            const data = {
                collection_date: this.savedData.collection_date,
                batch: this.savedData.batch
            };
            this.loadBulls(data);
        },
        async loadBulls (data) {
            this.savedData = data;
            const response = await axios.form(urls.semenCollection.pendingAnimal, {
                collection_date: data.collection_date,
                batch: data.batch

            });
            console.log('response', response.data.data);
            this.bullsData = response.data.data;
        },
        ss () {
            this.loading = true;
        },
        st () {
            this.loading = false;
        },
        addItem () {
            this.$refs.sc.addItem();
        }

    }
};
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

/*.item:first-child {*/
/*    grid-column-start: 1;*/
/*    grid-column-end: 6;*/
/*}*/
i:first-child, i.i-inline:first-child {
  margin-left: 1px;
  transform: rotate(225deg);
}

.text-white {
  color: white !important;
}

.bg-white {
  background-color: white;
}

.bg-grey {
  background-color: #f4f4f4;
}

.one {
  position: relative;
  top: 31px;
  z-index: 1;

}

.two {
  position: relative;
  top: -6px;

}
</style>
